<template>
  <section class="site-section" id="next-section">
    <div class="container">
      <div class="row mb-3">
        <div class="col-12 text-center" data-aos="fade">
          <h2 class="section-title mb-3">{{$options.name}}</h2>
        </div>
      </div>

      <div v-for="gallery in realizacie.galleries" :key="gallery.kategory_name">
        <h3 class="text-center border-bottom">{{gallery.kategory_name}}</h3>
        <div class="row no-gutters">
        <div
          class="col-md-6 col-lg-4 item text-center mb-4"
          v-for="image in gallery.images"
          :key="image"
        >
          <a :href="image.full" class="item-wrap fancybox" data-fancybox="gallery2">
            <span class="icon-search2"></span>
            <img class="img-fluid" :src="image.thumb" />
          </a>
        </div>
      </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Realizácie",
  metaInfo() {
    return {
      title: this.metaTagy.title,
      meta : [
        { name:"title", content: this.metaTagy.title },
        { name:"description", content: this.metaTagy.description },
        { name:"keywords", content: this.metaTagy.keywords },
        { property:"og:type", content:"website" },
        { property:"og:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"og:title", content: this.metaTagy.title },
        { property:"og:description", content: this.metaTagy.description },
        { property:"twitter:card", content:"summary_large_image" },
        { property:"twitter:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"twitter:title", content: this.metaTagy.title },
        { property:"twitter:description", content: this.metaTagy.description },
      ]
    }
  },
  data() {
    return {
      metaTagy: {
        title: "Realizácie | Predaj prírodného kameňa",
        keywords: "realizacie, andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description:"Realizácie z prírodného kameňa dodávaného firmou predajkamena.sk",
        urlPath: this.$router.fullpath
      },
      realizacie: null,
    };
  },
  async mounted() {
    this.$axios
      .get("/data/realizacie.json")
      .then((response) => (this.realizacie = response.data))
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>